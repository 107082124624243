<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="400px"
        min-width="300px"
        max-width="400px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addIncoTerm') : $t('message.updateIncoTerm') + ": " + incoTerm }}</span>
                <HelpPopUpV2 help-page-component="AddIncoTerm" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="incoTermForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg4 md4 pl-5 py-1 class="align-center">{{ $t('message.incoTerm') }}</v-flex>
                        <v-flex xs7 lg8 md8 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="Incoterm__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Incoterm__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addIncoTerm()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveIncoTerm()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {validationRules} from "@/store/modules/appic/constants";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddIncoTerm",
    components: {HelpPopUpV2},
    props: ['incoTermId','incoTerm','dialog','updateMode'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                title: v => !!v || this.$t('message.required')
            },
            validForm: true
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('incoterm', {
            Incoterm__id: 'Incoterm.id',
            Incoterm__title: 'Incoterm.title',
        },'statePrefix'),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('incoterm', {
            createIncoTerm: 'createIncoTerm',
            getIncoTermToUpdateById: 'getIncoTermToUpdateById',
            resetIncoTermToUpdate: 'resetIncoTermToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateIncoTerm: 'updateIncoTerm'
        }),
        addIncoTerm () {
            if(this.$refs.incoTermForm.validate()) {
                this.loading.add = true
                this.createIncoTerm()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.incoTermAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.incoTermNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.incoTermNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.incoTermNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveIncoTerm () {
            if(this.$refs.incoTermForm.validate()) {
                this.loading.save = true
                this.updateIncoTerm()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.incoTermUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.incoTermNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.incoTermNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.incoTermNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getIncoTermToUpdateById(this.incoTermId)
                        .then(() => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetIncoTermToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style scoped>

</style>